@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-violet-100: 110 79 250;
    --color-violet-90: 125 97 250;
    --color-violet-80: 142 117 250;
    --color-violet-60: 168 147 251;
    --color-violet-40: 198 186 253;
    --color-violet-20: 226 219 255;
    --color-violet-10: 241 237 255;
    --color-main: 251 250 255
  }
}
body {
  position: relative;
}

@font-face {
  font-family: "Gilroy-medium";
  src: local("medium"),
    url("./Asset/fonts/Gilroy-Medium-2.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-regular";
  src: local("regular"),
    url("./Asset/fonts/gilroy-regular-3.ttf") format("truetype");
}


@font-face {
  font-family: "Gilroy-bold";
  src: local("bold"),
    url("./Asset/fonts/gilroy-bold-4.ttf") format("truetype");
}


@font-face {
  font-family: "Gilroy-black";
  src: local("black"),
    url("./Asset/fonts/gilroy-black-6.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-itlic";
  src: local("black-italic"),
    url("./Asset/fonts/gilroy-blackitalic-7.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-heavy-italic";
  src: local("heavy-italic"),
    url("./Asset/fonts/Gilroy-HeavyItalic-8.ttf") format("truetype");
}


@font-face {
  font-family: "Gilroy-extrabold-italic";
  src: local("extra-bold-italic"),
    url("./Asset/fonts/Gilroy-ExtraBoldItalic-10.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-light";
  src: local("light"),
    url("./Asset/fonts/Gilroy-Light-11.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-light-italic";
  src: local("light-italic"),
    url("./Asset/fonts/Gilroy-LightItalic-12.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-thin";
  src: local("thin"),
    url("./Asset/fonts/Gilroy-Thin-13.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-thin-italic";
  src: local("thin-italic"),
    url("./Asset/fonts/Gilroy-ThinItalic-14.ttf") format("truetype");
}
